/* global System */
// register component(s) with react habitat

export default {
  BranchJobs: () =>
    System.import(
      /* webpackChunkName: "BranchJobs" */
      './Component'
    ),
}
